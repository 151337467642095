import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { WorkspaceLocale } from "./types";

const useWorkspaceLocales = <
  TData extends {} = JsonApiResponse<WorkspaceLocale[]>
>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<WorkspaceLocale[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<WorkspaceLocale[]>, PortalClientError, TData>(
    ["workspace_locales"],
    () => client.get(`/workspace_locales`),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      ...queryOptions,
    }
  );
};

export default useWorkspaceLocales;
