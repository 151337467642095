import { WorkspaceLocale } from "../../client";

/**
 * Retrieve a list of workspace locales that are not archived.
 * @param workspaceLocales An array of workspace locales.
 * @returns A filtered list of valid workspace locales.
 */
export const getSelectedWorkspaceLocales = (
  workspaceLocales: WorkspaceLocale[]
) => {
  return workspaceLocales
    .filter((workspaceLocales) => workspaceLocales.state !== "archived")
    .map((workspaceLocale) => workspaceLocale.locale?.data?.id);
};
