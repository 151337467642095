import { Form, Space, Typography } from "antd";

import WorkspaceDefaultLocaleSelect from "../WorkspaceDefaultLocaleSelect";
import WorkspaceLocaleSelect from "../WorkspaceLocaleSelect";

const Internationalization: React.FunctionComponent = () => {
  return (
    <Space
      direction="vertical"
      size={16}
      style={{ width: "100%", padding: "24px" }}
    >
      <Typography.Title level={4}>Internationalization</Typography.Title>
      <Typography.Title level={5}>Choose locale(s)</Typography.Title>
      <Typography.Text>
        Please choose the locale(s) and what you would like for participants to
        see by default in their experience.
      </Typography.Text>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
        <Form.Item label="Locale(s)" name="locales">
          <WorkspaceLocaleSelect />
        </Form.Item>
        <Form.Item label="Choose default language" name="defaultLocale">
          <WorkspaceDefaultLocaleSelect />
        </Form.Item>
      </Form>
    </Space>
  );
};

export default Internationalization;
