import { Button, notification } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import React from "react";

import Notifications from "../../assets/data/notifications";
import {
  PortalClientError,
  useCreateImage,
  useUpdateWorkspaceSettings,
  WorkspaceSettings,
} from "../../client";
import ImageUpload from "../../components/ImageUpload";
import { FormProps } from "../../types";
import { schema } from "./WorkspaceSettingsForm.schema";

export type WorkspaceSettingsFormValues = Pick<
  WorkspaceSettings,
  | "admin_login_text"
  | "admin_welcome_message"
  | "admin_favicon_url"
  | "admin_logo_url"
  | "tenant_feature_flag_descriptions"
>;

type WorkspaceSettingsFormProps = FormProps<WorkspaceSettingsFormValues> & {
  settings: WorkspaceSettingsFormValues;
};

const WorkspaceSettingsForm: React.FC<WorkspaceSettingsFormProps> = ({
  settings,
}) => {
  const { mutate: updateWorkspaceSettings, isLoading } =
    useUpdateWorkspaceSettings({
      onSuccess: () => {
        notification.success({
          message: "Success",
          description: Notifications.UpdateWorkspaceSettings.success,
        });
      },
      onError: (error) => {
        notification.error({
          message: "Error",
          description: `${Notifications.UpdateWorkspaceSettings.error} ${error.errors?.[0]?.detail}`,
        });
      },
    });

  const { mutateAsync: addImage } = useCreateImage({
    onError: () => {
      notification.error({
        message: "Error",
        description: Notifications.AddImage.error,
      });
    },
  });

  const handleSubmit = async (values: {
    admin_login_text: string;
    admin_welcome_message: string;
    admin_favicon_url: File | string;
    admin_logo_url: File | string;
  }) => {
    try {
      const workspaceSettings = {
        admin_login_text: values.admin_login_text,
        admin_welcome_message: values.admin_welcome_message,
        admin_favicon_url: "",
        admin_logo_url: "",
        tenant_feature_flag_descriptions: [],
      };
      if (values.admin_favicon_url instanceof File) {
        const faviconData = await addImage({
          file: values.admin_favicon_url,
          name: values.admin_favicon_url.name,
          kind: "AppIcon",
        });
        workspaceSettings["admin_favicon_url"] = faviconData.data.src;
      } else {
        workspaceSettings["admin_favicon_url"] = values.admin_favicon_url;
      }
      if (values.admin_logo_url instanceof File) {
        const logoForm = await addImage({
          file: values.admin_logo_url,
          name: values.admin_logo_url.name,
          kind: "AppIcon",
        });
        workspaceSettings["admin_logo_url"] = logoForm.data.src;
      } else {
        workspaceSettings["admin_logo_url"] = values.admin_logo_url;
      }
      updateWorkspaceSettings(workspaceSettings);
    } catch (error: unknown) {
      notification.error({
        message: "Error",
        description: `${Notifications.UpdateWorkspaceSettings.error} ${
          (error as PortalClientError).errors?.[0]?.detail
        }`,
      });
    }
  };

  return (
    <Formik<WorkspaceSettingsFormValues>
      enableReinitialize
      initialValues={settings}
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={schema}
    >
      {({
        touched,
        isValid,
        setFieldValue,
        validateField,
        dirty,
        isSubmitting,
        values,
      }) => (
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          <Form.Item
            hasFeedback={touched.admin_favicon_url}
            htmlFor="admin_favicon_url"
            label="Favicon"
            name="admin_favicon_url"
          >
            <ImageUpload
              defaultValue={values.admin_favicon_url}
              name="admin_favicon_url"
              onChange={(file) => {
                if (file.status !== "uploading") {
                  setFieldValue("admin_favicon_url", file);
                }
              }}
              onRemove={() => {
                setFieldValue("admin_favicon_url", undefined);
                validateField("admin_favicon_url");
              }}
            />
          </Form.Item>
          <Form.Item
            hasFeedback={touched.admin_logo_url}
            htmlFor="admin_logo_url"
            label="Logo"
            name="admin_logo_url"
          >
            <ImageUpload
              defaultValue={values.admin_logo_url}
              name="admin_logo_url"
              onChange={(file) => {
                if (file.status !== "uploading") {
                  setFieldValue("admin_logo_url", file);
                }
              }}
              onRemove={() => {
                setFieldValue("admin_logo_url", undefined);
                validateField("admin_logo_url");
              }}
            />
          </Form.Item>

          <Form.Item
            hasFeedback={touched.admin_login_text}
            htmlFor="admin_login_text"
            label="Login Text"
            name="admin_login_text"
          >
            <Input
              id="admin_login_text"
              name="admin_login_text"
              placeholder="Login Text"
            />
          </Form.Item>
          <Form.Item
            hasFeedback={touched.admin_welcome_message}
            htmlFor="admin_welcome_message"
            label="Welcome Message"
            name="admin_welcome_message"
          >
            <Input
              id="admin_welcome_message"
              name="admin_welcome_message"
              placeholder="Welcome Message"
            />
          </Form.Item>
          <Form.Item name="buttons" wrapperCol={{ push: 8, span: 8 }}>
            <Button
              disabled={!isValid || !dirty || isSubmitting}
              htmlType="submit"
              loading={isLoading}
              style={{ margin: "0 4px" }}
              type="primary"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};
export default WorkspaceSettingsForm;
