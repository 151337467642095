import { FeatureToggles } from "./FeatureToggleContext.types";

/** local storage key */
export const StorageKey = "maestro:feature_toggles";

/** active feature toggles */
export const activeFeatureToggles = {
  offer_invite_codes_enabled: false,
  internationalization_settings_enabled: true,
};

/**
 * Retrieve a list of feature toggles stored in local storage
 * @returns a list of key-value feature toggles
 */
export const getLocalFeatureToggles = () => {
  const value = localStorage.getItem(StorageKey);

  try {
    if (value) {
      const featureToggles = JSON.parse(value) as unknown;
      return featureToggles as FeatureToggles;
    }
  } catch (error: any) {
    localStorage.removeItem(StorageKey);
  }
};

/**
 * Retrieve a filtered list of feature toggles from local storage
 * @param featureToggles a set of feature toggles
 * @returns updated list of feature toggles using local storage values
 */
export const filterLocalFeatureToggles = (featureToggles: FeatureToggles) => {
  const localFeatureToggles = getLocalFeatureToggles();

  return Object.keys(featureToggles).reduce((accumulator, key) => {
    return {
      ...accumulator,
      [key]: localFeatureToggles?.[key] ?? featureToggles[key],
    };
  }, {});
};
