import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Locale } from "./types";

const useLocales = <TData extends {} = JsonApiResponse<Locale[]>>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Locale[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Locale[]>, PortalClientError, TData>(
    ["locales"],
    () => client.get(`/locales`),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      ...queryOptions,
    }
  );
};

export default useLocales;
