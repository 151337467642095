import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient, useQueryClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { UpdateWorkspaceLocale, WorkspaceLocale } from "./types";

const useUnarchiveWorkspaceLocale = (
  options?: UseMutationOptions<
    JsonApiResponse<WorkspaceLocale>,
    PortalClientError,
    UpdateWorkspaceLocale
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<WorkspaceLocale>,
    PortalClientError,
    UpdateWorkspaceLocale
  >(
    (payload) =>
      client.put(`/workspace_locales/${payload.id}/unarchive`, payload),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["workspace_locales"]);
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useUnarchiveWorkspaceLocale;
