import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import type { PortalClientError } from "../../types";
import type { JsonApiResponse } from "../types";
import { WorkspaceSettings } from "./types";

const useWorkspaceSettings = <
  TData extends {} = JsonApiResponse<WorkspaceSettings>
>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<WorkspaceSettings>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<WorkspaceSettings>, PortalClientError, TData>(
    ["workspace-settings"],
    () => client.get("/workspace_settings"),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      ...queryOptions,
      retry: (_failureCount, error) =>
        // 404 means the settings aren't set, so no point in retrying.
        error.status === 404 ? false : true,
    }
  );
};

export default useWorkspaceSettings;
