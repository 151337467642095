import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { serialise } from "kitsu-core";

import { useClient, useQueryClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { CreateWorkspaceLocale, WorkspaceLocale } from "./types";

const useCreateWorkspaceLocale = (
  options?: UseMutationOptions<
    JsonApiResponse<WorkspaceLocale>,
    PortalClientError,
    CreateWorkspaceLocale
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<WorkspaceLocale>,
    PortalClientError,
    CreateWorkspaceLocale
  >(
    (payload) =>
      client.post(`/workspace_locales`, serialise("workspace_locale", payload)),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["workspace_locales"]);
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useCreateWorkspaceLocale;
